@import "../../assets/scss/variables.scss";

.header {
  width: 100%;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  // background-color:rgba(255, 255, 255, 0.5);
  z-index: 1;
  .navbar{
    width: 100%;
    float: left;
    justify-content: space-between;
    padding: 16px 0;
    .logo{
      width: 240px;
      margin: 0px;
      padding: 5px 0 0;
    }   
    .navbar-collapse{
      flex-grow: initial;
      #menu {
        padding: 0;
        margin: 0;
        display: flex;
        li{
            display: flex;
            padding: 0px;
            margin: 0 18px;
            align-items: center;
            text-decoration: none;
            &:last-child{
              margin-right: 0px;
            }
            &.active{
              a{
                color: $TEXT_BLUE !important;
              }
            }
          a{
            font-size:$FONT_16;
            font-weight: 400;
            font-family: $SFPRODISPLAY;
            color:$TEXT_GREY !important;
            line-height: 24px;
            text-decoration: none;
          }
        }
      }
      .navbar-nav{
        .linktxt{
          font-size:$FONT_16;
          color:$TEXT_GREY !important;
          line-height: 24px;
          display: flex;
          padding: 0px;
          margin: 0 18px;
          align-items: center;
          text-decoration: none;
          &:last-child{
            margin-right: 0px;
          }
          &.active{
            color: $TEXT_BLUE !important;
          }
        }
        
      } 

    }
  }
}

@media only screen and (min-width:992px) and (max-width:1024px){
  .header{
    .navbar{
      padding: 10px 0;
    }  
  } 
}

@media only screen and (min-width:576px) and (max-width:767px) {
  .header{
    background-color: $BG_WHITE;
    .navbar{
      padding: 10px 0;
      .navbar-toggler{
        display: flex;
      }
      .navbar-collapse{
        position: absolute;
        left: 0px;
        top: 76px;
        width: 100%;
        background-color: $BG_WHITE;
        display: none !important;
        &.show{
          display: flex !important;
        }
        .navbar-nav{
          flex-direction: column;
          width: 100%;
          .linktxt{
            margin: 10px 15px;
          }
        } 
        #menu {
          padding: 10px 0;
          margin: 0;
          display: flex;
          li{
              display: flex;
              padding: 0px;
              margin: 10px 18px;
              align-items: center;
              text-decoration: none;
              &:last-child{
                margin-right: 0px;
              }
              &.active{
                a{
                  color: $TEXT_BLUE !important;
                }
              }
            a{
              font-size:$FONT_16;
              font-weight: 400;
              font-family: $SFPRODISPLAY;
              color:$TEXT_GREY !important;
              line-height: 24px;
              text-decoration: none;
            }
          }
        }
      } 
    
    } 
  } 
}

@media only screen and (max-width:575px) {
  .header{
    background-color: $BG_WHITE;
    .navbar{
      padding: 10px 0;
      .navbar-toggler{
        display: flex;
      }
      .navbar-collapse{
        position: absolute;
        left: 0px;
        top: 76px;
        width: 100%;
        background-color: $BG_WHITE;
        display: none !important;
        &.show{
          display: flex !important;
        }
        .navbar-nav{
          flex-direction: column;
          width: 100%;
          .linktxt{
            margin: 10px 15px;
          }
        } 
        #menu {
          padding: 10px 0;
          margin: 0;
          display: flex;
          li{
              display: flex;
              padding: 0px;
              margin: 10px 18px;
              align-items: center;
              text-decoration: none;
              &:last-child{
                margin-right: 0px;
              }
              &.active{
                a{
                  color: $TEXT_BLUE !important;
                }
              }
            a{
              font-size:$FONT_16;
              font-weight: 400;
              font-family: $SFPRODISPLAY;
              color:$TEXT_GREY !important;
              line-height: 24px;
              text-decoration: none;
            }
          }
        }
      } 
    } 
  } 
}