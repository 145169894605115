@import "./assets/scss/variables.scss";
.App {
  text-align: center;
  .container {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
  }
}

h1 {
  font-size: $FONT_56 !important;
  line-height: 150% !important;
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
}
h2 {
  font-size: $FONT_56 !important;
  line-height: 120% !important;
  margin-bottom: 0px;
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
}
h3 {
  font-size: $FONT_48 !important;
  line-height: 150% !important;
  margin-bottom: 0px;
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
}
p {
  font-size: $FONT_18 !important;
  line-height: 180% !important;
  margin: 0px !important;
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
}
button,
a {
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: 48px !important;
      }
      h2 {
        font-size: 48px !important;
      }
      h3 {
        font-size: 48px !important;
      }
      p {
        font-size: 16px !important;
        line-height: 150% !important;
        margin: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: 48px !important;
      }
      h2 {
        font-size: 48px !important;
      }
      h3 {
        font-size: 48px !important;
      }
      p {
        font-size: 16px !important;
        line-height: 150% !important;
        margin: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: 36px !important;
      }
      h2 {
        font-size: 36px !important;
      }
      h3 {
        font-size: 36px !important;
      }
      p {
        font-size: 16px !important;
        line-height: 150% !important;
        margin: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: 30px !important;
      }
      h2 {
        font-size: 30px !important;
      }
      h3 {
        font-size: 30px !important;
      }
      p {
        font-size: 14px !important;
        line-height: 150% !important;
        margin: 0px !important;
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: 30px !important;
      }
      h2 {
        font-size: 30px !important;
      }
      h3 {
        font-size: 30px !important;
      }
      p {
        font-size: 14px !important;
        line-height: 150% !important;
        margin: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: 30px !important;
      }
      h2 {
        font-size: 30px !important;
      }
      h3 {
        font-size: 30px !important;
      }
      p {
        font-size: 14px !important;
        line-height: 150% !important;
        margin: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: 30px !important;
      }
      h2 {
        font-size: 30px !important;
      }
      h3 {
        font-size: 30px !important;
      }
      p {
        font-size: 14px !important;
        line-height: 150% !important;
        margin: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .App {
    .container {
      padding-left: 16px;
      padding-right: 16px;
      h1 {
        font-size: $FONT_24 !important;
      }
      h2 {
        font-size: $FONT_24 !important;
      }
      h3 {
        font-size: $FONT_24 !important;
      }
      p {
        font-size: 16px !important;
        line-height: 24px !important;
        margin: 0px !important;
      }
    }
  }
}
