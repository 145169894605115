$BG_BLACK:#000000;
$BG_WHITE:#FFFFFF;
$BG_GREY_LIGHT:#F6F9FF;
$BG_BLUE:#3F7FFF;
$BG_WHITE_05:rgba(255, 255, 255, 0.5);

$TEXT_BLACK:#000000;
$TEXT_WHITE:#FFFFFF;
$TEXT_BLUE:#3F7FFF;
$TEXT_BLUE_04:#62ABF5;
$TEXT_GREY:#3A4468;
$TEXT_GREY_600:#585858;
$TEXT_GREY_LIGHT:#BBBBBB;
$TEXT_BLACK_08:#252525;
$TEXT_YELLOW:#FF9F0A;

$BORDER_GREY_LIGHT:#C8CAD2;
$BORDER_YELLOW:#FF9F0A;

// $SFPRODISPLAY: 'SF Pro Display';
$SFPRODISPLAY: 'SF Pro Display';
$font-medium:500;

// Font Size
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_30: 30px;
$FONT_48: 48px;
$FONT_56: 56px;