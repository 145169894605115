@import "./assets/scss/variables.scss";
body {
  margin: 0;
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
}

body,
html {
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
}
code {
  font-family: "SF Pro Display", "Roboto", sans-serif !important;
}
a {
  outline: none;
}
.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SmartFinDoc";
  src: url("./assets/fonts/SmartFinDoc.eot?voqvbw");
  src: url("./assets/fonts/SmartFinDoc.eot?voqvbw#iefix") format("embedded-opentype"),
    url("./assets/fonts/SmartFinDoc.ttf?voqvbw") format("truetype"),
    url("./assets/fonts/SmartFinDoc.woff?voqvbw") format("woff"),
    url("./assets/fonts/SmartFinDoc.svg?voqvbw#SmartFinDoc") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "SmartFinDoc" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Arrow_Down:before {
  content: "\e900";
}
.icon-Arrow_left:before {
  content: "\e901";
}
.icon-Copyright:before {
  content: "\e902";
}
.icon-Facebook:before {
  content: "\e903";
}
.icon-Instagram:before {
  content: "\e904";
}
.icon-Mail:before {
  content: "\e905";
}
.icon-Phone:before {
  content: "\e906";
}
.icon-Play:before {
  content: "\e907";
}
.icon-Right:before {
  content: "\e908";
}
.icon-Send:before {
  content: "\e909";
}
.icon-Twitter:before {
  content: "\e90a";
}
.icon-User:before {
  content: "\e90b";
}
.icon-Youtube:before {
  content: "\e90c";
}
.icon-que:before {
  content: "\e90d";
}
.icon-Close:before {
  content: "\e90f";
}
.icon-menu:before {
  content: "\e910";
  color: #3f7fff;
}
.icon-search:before {
  content: "\e90e";
}
.icon-linkedin:before {
  content: "\e911";
}
.icon-error:before {
  content: "\e912";
}
